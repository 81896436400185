<template>
  <div>
    <Modal
      v-model="visiblePreview"
      footer-hide
      width="800"
    >
      <p
        slot="header"
        class="text-center"
      >{{ fileName }}</p>
      <div>
        <!-- <Row class="m-b-5">
          <i-col class="text-right">
            <Button
              size="small"
              type="success"
              @click="viewBigImage"
            >查看原图</Button>
          </i-col>
        </Row> -->
        <img-light-box
          ref="imgLightBox"
          :showModal="visiblePreview"
          :sortIndex="0"
        ></img-light-box>
      </div>
    </Modal>
  </div>
</template>

<script>
import ImgLightBox from '@/components/common/ImgVideoLightBox'
export default {
  components: { ImgLightBox },
  data () {
    return {
      visiblePreview: false,
      fileName: '',
      info: {}
    }
  },
  methods: {
    showModal (data) {
      if (!data) {
        this.$Notice.error({ desc: '非法访问，缺少必要参数' })
        return false
      }

      this.info = data
      const postData = {
        startIndex: 0,
        imgList: this.info.taskitemFileList
      }
      this.fileName = `${this.info.stationName} ${this.info.code}`
      this.$refs.imgLightBox.init(postData)
      this.visiblePreview = true
    }
  }
}
</script>

import { getPublisherAssetList } from '@/api/rim/asset'
import { getStationByAssetId } from '@/api/rim/station'

export const indexMixins = {
  data () {
    return {
      statusArray: [
        { value: 0, name: '草稿' },
        { value: 1, name: '待确认' },
        { value: 2, name: '申请中' },
        { value: 3, name: '已申请' }
      ],
      pagesizeSetting: [15, 50, 100, 200, 500],
      publisherId: this.$store.getters.token.userInfo.publisherId
    }
  },
  methods: {
    /**
     * 获取当前登录用户的资产
     * @returns
     */
    async getAssetData () {
      const result = await getPublisherAssetList({ publisherId: this.publisherId })
      return result
    },
    /**
     * 获取指定资产id下的站点
     * @param {Number} assetId 资产id
     * @returns
     */
    async getStationData (assetId = null) {
      if (!assetId) {
        this.stationArray = []
        return
      }
      const result = await getStationByAssetId({ assetId })
      return result
    }
  }
}

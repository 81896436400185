<template>
  <div>
    <Modal
      v-model="detailModal"
      width="800"
    >
      <p
        slot="header"
        class="text-center"
      >施工计划详情</p>
      <div class="m-b-10">
        <h4 class="workplatform-title m-t-10 m-b-10">基础信息</h4>
        <Row
          :gutter="8"
          class="m-b-10"
        >
          <i-col span="8">
            <span class="title">作业日期</span><span>{{ info.actionDate }}</span>
          </i-col>
          <i-col span="8">
            <span class="title">线路</span><span>{{ info.assetName }}</span>
          </i-col>
          <i-col span="8">
            <span class="title">站点</span><span>{{ info.stationName }}</span>
          </i-col>
        </Row>
        <Row
          :gutter="8"
          class="m-b-10"
        >
          <i-col span="8">
            <span class="title">位置</span><span>{{ position }}</span>
          </i-col>
          <i-col span="8">
            <span class="title">作业时间段</span><span>{{ info.workTime }}</span>
          </i-col>
          <i-col span="8">
            <span class="title">任务数量</span><span>{{ info.taskCount }}</span>
          </i-col>
        </Row>
        <Row
          :gutter="8"
          class="m-b-10"
        >
          <i-col span="8">
            <span class="title">任务类型</span><span>{{ info.taskTypes }}</span>
          </i-col>
          <i-col span="8">
            <span class="title">施工人数</span><span>{{ info.workerNumber }}</span>
          </i-col>
          <i-col span="8">
            <span class="title">施工单位</span><span>{{ info.supplierCompanyName }}</span>
          </i-col>
        </Row>
        <Row
          :gutter="8"
          class="m-b-10"
        >
          <i-col span="8">
            <span class="title">任务发起方</span><span>{{ info.workManagerName||'/' }}</span>
          </i-col>
          <i-col span="8">
            <span class="title">备注</span><span>{{ info.remark||'/' }}</span>
          </i-col>
          <i-col span="8">
            <span class="title">状态</span><span>{{ info.status }}</span>
          </i-col>
        </Row>
      </div>
      <div
        v-if="isModify"
        class="m-b-10"
      >
        <h4 class="workplatform-title m-t-10 m-b-10">可编辑信息</h4>
        <Form
          ref="modifyInfo"
          :model="modifyData"
          :rules="ruleValidate"
          :label-width="100"
        >
          <Row :gutter="8">
            <i-col span="12">
              <FormItem
                label="施工时间段"
                prop="workTime"
              >
                <Input
                  v-model="modifyData.workTime"
                  size="small"
                  placeholder="填写施工时间段"
                ></Input>
              </FormItem>
            </i-col>
            <i-col span="12">
              <FormItem
                label="施工人数"
                prop="workerNum"
              >
                <InputNumber
                  v-model="modifyData.workerNum"
                  :min="1"
                  style="width:100%"
                  size="small"
                  placeholder="填写施工人数"
                ></InputNumber>
              </FormItem>
            </i-col>
          </Row>
          <FormItem label="备注">
            <Input
              v-model="modifyData.remark"
              size="small"
              type="text"
              placeholder="填写备注"
            />
          </FormItem>
          <FormItem class="text-right">
            <Button
              type="success"
              size="small"
              :loading="submitLoading"
              @click="onSubmit"
            >确认编辑</Button>
          </FormItem>
        </Form>
      </div>
      <div>
        <h4 class="workplatform-title m-t-10 m-b-10">计划明细</h4>
        <div>
          <Table
            stripe
            max-height="620"
            size="small"
            :data="tableList"
            :columns="tableColumns"
            :loading="tableLoading"
          ></Table>
          <div class="paging_style">
            <Page
              size="small"
              :total="total"
              :page-size="query.pageSize"
              show-total
              show-elevator
              :current="query.pageNumber"
              @on-change="onChangePageNum"
            ></Page>
          </div>
        </div>
      </div>
    </Modal>
    <view-task-file ref="viewTaskFile" />
  </div>
</template>

<script>
import { updateWorkday, getWorkdayTaskPage } from '@/api/msp/workdaySchedule'

import ViewTaskFile from './ViewTaskFile.vue'
export default {
  props: {
    isModify: {
      type: Boolean,
      default: false
    },
    onSuccess: {
      type: Function,
      default: () => { },
      required: false
    }
  },
  components: { ViewTaskFile },
  data () {
    return {
      detailModal: false,
      info: {},
      submitLoading: false,
      modifyData: {
        workdayId: null,
        workTime: '',
        workerNum: 1,
        remark: ''
      },
      ruleValidate: {
        workTime: [
          { required: true, type: 'string', message: ' ', trigger: 'blur' }
        ],
        workerNum: [
          { required: true, type: 'number', message: ' ', trigger: 'blur' }
        ]
      },
      query: {
        pageNumber: 1,
        pageSize: 15,
        workdayId: null
      },
      tableList: [],
      total: 0,
      tableLoading: false,
      tableColumns: [
        { title: '编号', align: 'center', minWidth: 120, key: 'code' },
        {
          title: '位置',
          align: 'center',
          minWidth: 120,
          key: 'potionName',
          render: (h, { row }) => {
            const html = []
            row.positionList.forEach(item => {
              html.push(h('p', `${item.floor}层 - ${item.positionName}`))
            })
            return h('div', html)
          }
        },
        { title: '资源类型', align: 'center', minWidth: 120, key: 'deviceModelName' },
        // { title: '单品', align: 'center', minWidth: 120, key: 'code' },
        {
          title: '作业画面',
          align: 'center',
          minWidth: 120,
          render: (h, { row }) => {
            if (row.taskitemFileList && row.taskitemFileList.length > 0) {
              if (row.taskitemFileList.some(x => (x.mimeType.includes('video') || x.mimeType.includes('audio')))) {
                return h('a', {
                  on: {
                    click: () => {
                      // const postData = {
                      //   startIndex: 0,
                      //   imgList: row.taskitemFileList
                      // }
                      // this.fileName = `${row.stationName} ${row.code}`
                      // this.$refs.imgLightBox.init(postData)
                      // this.visiblePreview = true
                      this.$nextTick(() => {
                        this.$refs.viewTaskFile.showModal(row)
                      })
                    }
                  },
                  style: {
                    cursor: 'pointer'
                  }
                }, '查看素材')
              } else {
                return h('div', [
                  h(
                    'img',
                    {
                      domProps: {
                        src: row.taskitemFileList[0].fileKey + '?x-oss-process=image/resize,w_64'
                      },
                      on: {
                        click: () => {
                          this.$nextTick(() => {
                            this.$refs.viewTaskFile.showModal(row)
                          })
                        }
                      },
                      style: {
                        width: '64px',
                        cursor: 'pointer'
                      }
                    }
                  )
                ])
              }
            } else {
              return h('span', '暂无画面')
            }
          }
        },
        { title: '作业名称', align: 'center', minWidth: 120, key: 'taskName' },
        { title: '任务发起方', align: 'center', minWidth: 120, key: 'createCompanyName' },
        { title: '任务类型', align: 'center', minWidth: 120, key: 'taskTypeName' }
        // { title: '用途', align: 'center', minWidth: 120, key: 'code' },
        // { title: '备注', align: 'center', minWidth: 120, key: 'code' }
      ],
      visiblePreview: false,
      fileName: ''
    }
  },
  computed: {
    position () {
      if (this.info.positionList && this.info.positionList.length) {
        const names = []
        this.info.positionList.forEach(item => {
          names.push(`${item.floor}层 - ${item.positionName}`)
        })
        return names.toString()
      } else {
        return ''
      }
    }
  },
  methods: {
    showModal (data) {
      this.info = data
      this.modifyData.workTime = this.info.workTime
      this.modifyData.workdayId = this.info.id
      this.modifyData.workerNum = this.info.workerNumber
      this.modifyData.remark = this.info.remark

      this.query.workdayId = this.info.id
      this.getTableData()
      this.detailModal = true
    },
    onSubmit () {
      this.$refs.modifyInfo.validate((valid) => {
        if (valid) {
          this.$Modal.confirm({
            title: '操作提示',
            content: '确认信息无误，并提交？',
            onOk: async () => {
              this.submitLoading = true
              const reuslt = await updateWorkday(this.modifyData).finally(() => { this.submitLoading = false })
              if (reuslt && reuslt.success) {
                this.$Notice.success({ desc: '操作成功' })
                this.onSuccess()
              }
            }
          })
        }
      })
    },
    async getTableData () {
      this.tableLoading = true
      const reuslt = await getWorkdayTaskPage(this.query).finally(() => { this.tableLoading = false })
      if (reuslt && !reuslt.errcode) {
        this.tableList = reuslt.list
        this.total = reuslt.totalRow
      }
    },
    onChangePageNum (curPage) {
      this.query.pageNumber = curPage
      this.getTableData()
    }
  }
}
</script>
